@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #14b8a6 50.63%,
    #fdd017 100%,
    #fb923c 117.04%
  );
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #fb923c 21.43%,
    #fdd017 50.63%,
    #14b8a6 100%,
    #406aff 117.04%
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #14b8a6 50.63%,
    #fdd017 100%,
    #fb923c 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#FF56F6] to-[#42A6E3] font-bold font-primary hover:from-[#42A6E3] hover:to-[#FF56F6];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}

.glass-effect {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 8px;
  padding-left: 6px;
  padding-right: 2px;
}
