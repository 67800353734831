.main-logo {
  --bs-navbar-brand-font-size: 2.25rem;
}

.main-logo span {
  transition: 0.5s ease-out;
}

.main-logo:hover span {
  color: #ffffff;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
}

.menu-item span {
  transition: 0.5s ease-out;
}

.menu-item:hover span {
  color: #fff;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
}
